import * as Sentry from "@sentry/browser";

const dsn = ['production', 'staging'].includes(process.env.NODE_ENV)
  ? "https://e85f0b67473c3b903d1d1c2c5544df48@o1139896.ingest.sentry.io/4506191764586496"
  : "";

Sentry.init({
  dsn,
  environment: process.env.NODE_ENV,
  sampleRate: 1.0,
  tracesSampleRate: 0,
  autoSessionTracking: true,
  denyUrls: [/zdassets/, /announcekit/],
  ignoreErrors: [
    'Fetch is aborted',
    'Load failed'
  ]
});

window.Sentry = Sentry;
